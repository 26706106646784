function useFormatterPrices(price: number) {
  const formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
  })

  return formatter.format(price)
}

export default useFormatterPrices
